import React, { Component } from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import conIcon from "../images/gray/7.svg"
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';
import { isAuthenticated } from "../core/util";


class CongratulationPage extends Component {
    state = {
        contractId:null,
        recordId:null,
        islogged:null,
        btnTxt:"Download Your Contract"
    }
    
    componentDidMount = async() => {
        
        if (isAuthenticated()) {
            this.setState({ islogged: true})
        } else {
            this.setState({ islogged: false})
        }
       /*  if (this.props.step2response && !this.props.step2response.userId) {
            restrictRouteForStep(this.props.location);
            this.clearAllSteps(false) */
            
            let params = new URLSearchParams(this.props.location.search);
            let paramId = params.get('q');
            let recordId = params.get('id');
            if (paramId) {
                this.setState({ contractId: paramId,recordId:recordId})
            } else {
                navigate("/vehicleInfo/?r=1"+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
            }
    }

    clearAllSteps = (isNavigate) => {
        this.props.clearAllSteps();
        if (isNavigate) {
            navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
        }
    }

    b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    pdfdown = (contractId,recordId) => {
        this.setState({ btnTxt: "Loading ..."})

        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getpdf';
          fetch(uri,{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({
                contractId: contractId,
                recordId:recordId
              })
            })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    const contentType = 'application/pdf';
                    const blob = this.b64toBlob(res.data, contentType);
                    var blobUrl = window.URL.createObjectURL(blob);
                    a.href = blobUrl;
                    a.download = 'contract.pdf';
                    a.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.setState({ btnTxt: "Download Your Contract"})
                } else {
                    this.setState({ btnTxt: "Failed"})
                }
          });
    }


    render() {
        const { contractId,btnTxt,islogged,recordId } = this.state;
        if(!contractId) return '';
        /* window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event':'conversion','send_to': 'AW-714635181/'+contractId})
        window.dataLayer.push({'event':'conversion','send_to': 'UA-146273359-1/'+contractId}) */
        return (
            <Layout layoutType="main">
                <section className={global.dealertoken?'congratulation-section grayish':'congratulation-section'}>
                    {/* <Wizard></Wizard> */}
                    <div className="custom-container vehinfo">
                        <div className="body-content congratulation-content ">
                            <div className="congratulation-container">
                            <img src={conIcon} alt="" />
                            <h1>Congratulations!</h1>
                            <h4>You have successfully purchased your coverage plan.</h4>
                            {contractId!="pending" &&
                            <>
                            <p>Your Contract ID is <b>{contractId}</b></p>
                            <p><button className="down-btn2" onClick={(e) => {this.pdfdown(contractId,recordId)}}>{btnTxt}</button></p>
                            </>
                            }
                        </div>
                    </div>
                </div>
                {/* {global.dealertoken?'':
                <div className="vehicle-bottom-line bottom-line">
                    <img src={bottomLine} alt="line" />
                </div>
                } */}
                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CongratulationPage);
